module services {
    export module master {
        export class permitService implements interfaces.master.IPermitService {
            static $inject = ["$resource", "ENV","$http","$timeout","generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }
            
            getList(): ng.resource.IResourceClass<interfaces.master.IPermitDisplay> {
                return this.$resource<interfaces.master.IPermitDisplay>(this.ENV.DSP_URL + "Permit/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber', 
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

            getPermitDetail(): ng.resource.IResourceClass<interfaces.master.IPermit> {
                return this.$resource<interfaces.master.IPermit>(this.ENV.DSP_URL + "Permit/GetPermitDetail",
                    {
                        permId: "@permId",
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            createNewVersion(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/CreateNewVersion",
                    {
                        id: "@id",
                    }, {
                        save: {
                            method: 'POST'
                        }
                });
            }

            //Change Status
            changeStatus(): ng.resource.IResourceClass<interfaces.master.IPermit> {
                return this.$resource<interfaces.master.IPermit>(this.ENV.DSP_URL + "Permit/ChangeStatus", {
                }, {
                        save: {
                            method: 'POST'
                        }
                    });
            }

            createNewPermit(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/CreateNew", {
                });
            }

            savePermit(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/SavePermit", {
                });
            }
            
            getRestrictionsDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Permit/GetRestrictionsDropdown", {
                    countryId: '@countryId',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            getRestrictionsForCurrentEntity(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Permit/GetRestrictionsForCurrentEntity", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            

            getValidPermitList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Permit/GetValidPermitList", {
                    entityId: '@entityId',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            getRequiredProductCountryDropdown(entityId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Permit/GetRequiredProductCountryDropdown", {
                    entityId: entityId,
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            GetDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Permit/GetDropdown", {
                    consignmentRestrictionId: "@consignmentRestrictionId",
                    searchText: "@searchText"
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            RunPermitAllocationBasedOnTariffCode(permId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/RunPermitAllocationBasedOnTariffCode", {
                    permId: permId,
                });
            }

            deleteConsignee(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/DeleteConsignee", {
                });
            }

            deleteSupplier(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/DeleteSupplier", {
                });
            }

            deleteProduct(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/DeleteProduct", {
                });
            }

            deleteQuotaAllocation(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/DeleteQuotaAllocation", {
                });
            }

            GetDropdownForQuotaAllocation(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Permit/GetDropdownForQuotaAllocation", {
                    permitId: '@permitId',
                    searchText: '@searchText'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }

            GetDropdownQuotaType(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Permit/GetDropdownQuotaType", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }


            /*getPackagesLinkedToPermitQuota(packagesSearch: interfaces.master.IPackagesSearch): ng.resource.IResourceClass<interfaces.master.IPackagesLinkedToQuota> {
                return this.$resource<interfaces.master.IPackagesLinkedToQuota>(this.ENV.DSP_URL + "Permit/GetPackagesLinkedToPermitQuota", {
                    packagesSearch: packagesSearch,
                }, {
                        query: {
                            method: 'GET',
                            isArray: true
                        }
                    });
            }*/

            getPackagesLinkedToPermitQuota(): ng.resource.IResourceClass<interfaces.master.IPackagesLinkedToQuota> {
                return this.$resource<interfaces.master.IPackagesLinkedToQuota>(this.ENV.DSP_URL + "Permit/GetPackagesLinkedToPermitQuota", {
                    packagesSearch: '@packagesSearch'
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                            }
                        }
                    });
            }

            preAllocatePackagesToPermit(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/PreAllocatePackagesToPermit", {
                    packages: '@packages'
                }, {
                        query: {
                            method: 'POST',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                            }
                        }
                    });
            }

            
            reversePackagesSKUQuota(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Permit/ReversePackagesSKUQuota", {
                    packages: '@packages'
                }, {
                        query: {
                            method: 'POST',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                            }
                        }
                    });
            }

            


            getPackagesLinkedToPermitQuotaExcel(packagesSearch:interfaces.master.IPackagesSearch): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Permit/GetPackagesLinkedToPermitQuotaExcel';

              
                return this.$http({
                    method: 'POST',
                    responseType: 'arraybuffer',
                    url: url,
                    data:packagesSearch,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'PackageQuota.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            } 


            getPermitQuotaBalance(): ng.resource.IResourceClass<interfaces.master.PermitQuotaBalance> {
                return this.$resource<interfaces.master.PermitQuotaBalance>(this.ENV.DSP_URL + "Permit/GetPermitQuotaBalance",
                    {
                        permId: "@permId",
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );
            }

            

            

        }


    }
    angular.module("app").service("permitService", services.master.permitService);
}